import Component from "../component";

import { gsap } from "../../gsap";
import { ScrollTrigger } from "../../gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const MEDIA = window.matchMedia("(max-width: 1200px)");
export default class Header extends Component {
  _colored = false;
  _lock = false;
  _open = false;

  constructor(...args) {
    super(...args);
    this._retrieveDom();
  }

  _retrieveDom() {
    this.transparentSections = document.querySelectorAll(
      "[data-header-transparent]"
    );
    this.onglets = this.dom.component.querySelectorAll(".onglet");
    this.burgerButton = this.dom.component.querySelector(".burger");
    this.backButton = this.dom.component.querySelector(".back_button");
    this.openButton = this.dom.component.querySelectorAll(
      ".mobile-target-click"
    );
  }

  activeColored = () => {
    if (this._lock) return;
    this._colored = true;
    this.dom.component.classList.add("colored");
  };

  disableColored = () => {
    if (this._lock) return;
    this._colored = false;
    this.dom.component.classList.remove("colored");
  };

  bind() {
    if (this.transparentSections) {
      this.transparentSections.forEach(section => {
        gsap.fromTo(
          this.dom.component,
          {},
          {
            scrollTrigger: {
              trigger: section,
              start: "top 0%",
              end: "bottom 50%",
              onEnter: () => this.disableColored(),
              onEnterBack: () => this.disableColored(),
              onLeave: () => this.activeColored(),
              onLeaveBack: () => {
                if (window.scrollY >= 100) this.activeColored();
              }
            }
          }
        );
      });
    }

    this.onglets.forEach(onglet => {
      onglet.addEventListener("mouseenter", () => {
        this.activeColored();
        this._lock = true;
      });
      onglet.addEventListener("mouseleave", () => (this._lock = false));
    });

    // MOBILE ONLY EVENT
    if (!MEDIA.matches) return;

    this.burgerButton.addEventListener("click", () => {
      this.dom.component.classList.toggle("menu_open");

      if (!this._open) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "visible";
      }

      this._open = !this._open;
    });

    this.openButton.forEach(button => {
      button
        .querySelector(".onglet_title_mobile")
        .addEventListener("click", () => {
          button
            .querySelector(".onglet_child")
            .classList.toggle("onglet_child_open");
          this.dom.component.classList.toggle("menu_child_open");
        });
    });

    this.backButton.addEventListener("click", () => {
      const el = document.querySelector(".onglet_child_open");
      el.classList.toggle("onglet_child_open");
      this.dom.component.classList.toggle("menu_child_open");
    });
  }
}
