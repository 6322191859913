import Component from "../component";
import Swiper, { Mousewheel } from "swiper";

export default class SliderCards extends Component {
  constructor(...args) {
    super(...args);

    this._retrieveDOM();
  }

  _retrieveDOM() {
    this.dom.slide = this.dom.component.querySelectorAll(".swiper-slide");
    this.dom.slider = this.dom.component.querySelector(".js-slider");
  }

  // _setCursor(){

  //   this.dom.slide.length
  // }
  // _removeCursor(){
  //   this.dom.slider
  // }

  init() {
    this.isLoop =
      "loop" in this.dom.slider.dataset && this.dom.slide.length >= 6;
    this.Slider = new Swiper(this.dom.slider, {
      modules: [Mousewheel],
      direction: "horizontal",
      slidesPerView: 1,
      spaceBetween: 20,
      breakpoints: {
        769: {
          spaceBetween: 20,
          slidesPerView: 2
        },
        1024: {
          spaceBetween: 20,
          slidesPerView: 3
        },
        1440: {
          spaceBetween: 20,
          slidesPerView: 4
        }
      },
      loop: this.isLoop,
      mousewheel: { forceToAxis: true },
      freeMode: true
    });
  }
  /*
    init() {
    }
    unbind() {
    }
    */
  // bind() {
  //   super.bind();
  //   console.log("bind");
  // }
  resize(width, height) {
    if (this.dom.slide.length <= this.Slider.params.slidesPerView) {
      this.dom.slider.dataset.cursorDisabled = "";
    } else {
      delete this.dom.slider.dataset.cursorDisabled;
    }
  }
}
